const images = [
  require("./image-1.jpg"),
  require("./image-2.jpg"),
  require("./image-3.jpg"),
  require("./image-4.jpg"),
  require("./image-5.jpg"),
  require("./image-6.jpg"),
  require("./image-7.jpg"),
  require("./image-8.jpg"),
  require("./image-9.jpg"),
  require("./image-10.jpg"),
  require("./image-11.jpg"),
  require("./image-12.jpg"),
  require("./image-13.jpg"),
  require("./image-14.jpg"),
  require("./image-15.jpg"),
  require("./image-16.jpg"),
];

export default images;
