import React from "react";

const ImpressumContent = () => {
  return (
    <div style={{ padding: "20px", color: "#3a3a3a" }}>
      <h3 style={{ color: "#2a2a2a", marginBottom: "10px" }}>
        KobyHousing - Elisabeth Kobyklo
      </h3>
      <p style={{ margin: "5px 0" }}>Altenhagenerstrasse 285</p>
      <p style={{ margin: "5px 0" }}>33729 Bielefeld</p>
      <p style={{ margin: "5px 0" }}>Tel. 0157 53048561</p>
      <p style={{ margin: "5px 0" }}>
        Email:{" "}
        <a
          href="mailto:kobyhousing@gmail.com"
          style={{ color: "#718476", textDecoration: "none" }}
        >
          kobyhousing@gmail.com
        </a>
      </p>

      <h4 style={{ color: "#2a2a2a", marginTop: "20px", marginBottom: "10px" }}>
        Umsatzsteuer-Identifikationsnummer gemäß § 27 a:
      </h4>
      <p style={{ margin: "5px 0" }}>Wird nachgereicht</p>

      <h4 style={{ color: "#2a2a2a", marginTop: "20px", marginBottom: "10px" }}>
        Verantwortlich für den Inhalt:
      </h4>
      <p style={{ margin: "5px 0" }}>Elisabeth Kobyklo</p>

      <h4 style={{ color: "#2a2a2a", marginTop: "20px", marginBottom: "10px" }}>
        Haftungshinweis:
      </h4>
      <p style={{ margin: "5px 0", color: "#4a4a4a" }}>
        Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung
        für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten
        sind ausschließlich deren Betreiber verantwortlich.
      </p>
    </div>
  );
};

export default ImpressumContent;
