import "./App.scss";
import CookieBanner from "./components/CookieBanner";
import AppNavigator from "./navigator/AppNavigator";
import { BookingProvider } from "./navigator/BookingContext";

function App() {
  return (
    <div className="app">
      <AppNavigator />
      <CookieBanner />
    </div>
  );
}

export default App;
