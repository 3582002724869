import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import emailjs from "@emailjs/browser";

import {
  EMAILJS_SERVICE_ID,
  EMAILJS_CONTACT_TEMPLATE_ID,
  EMAILJS_USER_ID,
} from "../emailjsConfig.js";

const ContactScreen = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    copy: false,
    captcha: "",
  });

  const location = useLocation();

  // Extract subject from query string
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const subject = params.get("subject");
    if (subject) {
      setFormData((prevData) => ({
        ...prevData,
        subject: subject,
      }));
    }
  }, [location.search]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        EMAILJS_SERVICE_ID,
        EMAILJS_CONTACT_TEMPLATE_ID,
        {
          name: formData.name,
          email: formData.email,
          subject: formData.subject || "Kein Betreff",
          message: formData.message,
        },
        EMAILJS_USER_ID
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          alert("E-Mail wurde erfolgreich gesendet!");
          setFormData({
            name: "",
            email: "",
            subject: "",
            message: "",
            copy: false,
            captcha: "",
          });
        },
        (error) => {
          console.error("Failed to send email:", error);
          alert("Es gab einen Fehler beim Senden der E-Mail.");
        }
      );
  };

  return (
    <div className="contact-screen">
      <Header />
      <section className="contact-form-section">
        <h2 className="title">Eine E-Mail senden</h2>
        <p className="subtitle">
          Alle mit (<span>*</span>) markierten Felder werden benötigt.
        </p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <label>
            Name <span>*</span>
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />

          <label>
            E-Mail <span>*</span>
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <label>Betreff</label>
          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
          />

          <label>
            Nachricht <span>*</span>
          </label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />

          <button type="submit">E-Mail senden</button>
        </form>
      </section>
      <Footer />
    </div>
  );
};

export default ContactScreen;
