import React from "react";
import { Link } from "react-router-dom"; // Импортируем Link для навигации

import tinyHausImages from "../../assets/images/tiny-haus/tinyHausImages";
import wgZimmerImages from "../../assets/images/wg-zimmer/wgZimmerImages";
import apartmentsImages from "../../assets/images/apartments/apartmentsImages";
import aappartraumImages from "../../assets/images/aappartraum/aappartraumImages";
import apartmentsEngerImages from "../../assets/images/apartments-enger/apartmentsEngerImages";
import postServiceImages from "../../assets/images/post-service/postServiceImages";

// Массив с данными для карточек цен
const pricesCards = [
  {
    name: "Tiny Haus",
    price: "79,00 €",
    period: "/pro Tag",
    buttonLabel: "Buchen",
    image: tinyHausImages[1],
    altText: "Studio",
    apartmentLink: "/apartment-info/tiny-haus",
    contactLink: "#book-section",
  },
  {
    name: "WG-Zimmer",
    price: "49,00 €",
    period: "/pro Tag",
    buttonLabel: "Anfragen",
    image: wgZimmerImages[7],
    altText: "WG-Zimmer",
    apartmentLink: "/apartment-info/wg-zimmer",
    contactLink: "/kontakt?subject=WG-Zimmer - Anfragen",
  },
  {
    name: "Apartments Herford",
    price: "89,00 €",
    period: "/pro Tag",
    buttonLabel: "Buchen",
    image: apartmentsImages[6],
    altText: "Apartment Herford",
    apartmentLink: "/apartment-info/apartments",
    contactLink: "#book-section",
  },
  {
    name: "Aappartraum",
    price: "89,00 €",
    period: "/pro Tag",
    buttonLabel: "Buchen",
    image: aappartraumImages[2],
    altText: "Aappartraum Herford",
    apartmentLink: "/apartment-info/aappartraum",
    contactLink: "#book-section",
  },
  {
    name: "Apartments Enger",
    price: "89,00 €",
    period: "/pro Tag",
    buttonLabel: "Buchen",
    image: apartmentsEngerImages[2],
    altText: "Apartments Enger",
    apartmentLink: "/apartment-info/apartments-enger",
    contactLink: "#book-section",
  },
  {
    name: "Post Service",
    price: "ab 29,00 €",
    period: "/pro Monat",
    buttonLabel: "Anfragen",
    image: postServiceImages[1],
    altText: "Box Storage",
    apartmentLink: "/apartment-info/post-service",
    contactLink: "/kontakt?subject=Post Service - Anfragen",
  },
];

// Функция для прокрутки страницы с небольшим смещением от якоря
const scrollToAnchor = (anchorId, offset = 0) => {
  const element = document.getElementById(anchorId);
  if (element) {
    window.scrollTo({
      top: element.offsetTop - offset,
      behavior: "smooth",
    });
  }
};

const OurPricesSection = () => {
  return (
    <section className="our-prices-section">
      <h2>Unsere Preise</h2>
      <div className="prices-cards">
        {pricesCards.map((priceCard) => (
          <div key={priceCard.name} className="price-card">
            <img src={priceCard.image} alt={priceCard.altText} />
            <div className="price-info">
              <h3>{priceCard.name}</h3>
              <p className="price">
                {priceCard.price}{" "}
                <span className="period">{priceCard.period}</span>
              </p>
              {priceCard.contactLink === "#book-section" ? (
                <a
                  href={priceCard.contactLink}
                  className="contact-link"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToAnchor("book-section", 215); // Прокрутка с смещением 100px
                  }}
                >
                  <button>{priceCard.buttonLabel}</button>
                </a>
              ) : (
                <Link to={priceCard.contactLink} className="contact-link">
                  <button>{priceCard.buttonLabel}</button>
                </Link>
              )}
              <p className="tax-info">Preis inkl. MwSt.</p>
            </div>
            <Link to={priceCard.apartmentLink} className="mehr-erfahren-link">
              Mehr erfahren
            </Link>{" "}
            {/* Используем Link для навигации */}
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurPricesSection;
