import React, { createContext, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const BookingContext = createContext();

export const BookingProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    apartment: null,
    checkIn: null,
    checkOut: null,
    betreff: "",
  });

  const location = useLocation(); // Используем для отслеживания маршрута

  useEffect(() => {
    // Проверяем, если мы на странице бронирования (например, '/buchen'), сохраняем данные
    if (location.pathname !== "/buchen") {
      // Если мы не на странице бронирования, очищаем контекст
      setFormData({
        apartment: null,
        checkIn: null,
        checkOut: null,
        betreff: "",
      });
    }
  }, [location]);

  return (
    <BookingContext.Provider value={{ formData, setFormData }}>
      {children}
    </BookingContext.Provider>
  );
};

export const useBooking = () => useContext(BookingContext);
