import Footer from "../components/Footer";
import Header from "../components/Header";
import BookSection from "../components/home-screen-sections/BookSection";
import CategoriesSection from "../components/home-screen-sections/Categories";
import FacilitiesSection from "../components/home-screen-sections/FacilitiesSection";
import GallerySection from "../components/home-screen-sections/GallerySection";
import HeroSection from "../components/home-screen-sections/HeroSection";
import OurPricesSection from "../components/home-screen-sections/OurPricesSection";

const HomeScreen = () => {
  return (
    <div className="home-screen">
      <Header home />
      <HeroSection />
      <BookSection />
      <CategoriesSection />
      <FacilitiesSection />
      <OurPricesSection />
      <GallerySection />
      <Footer />
    </div>
  );
};

export default HomeScreen;
