import React from "react";
import { Link } from "react-router-dom"; // Импортируем Link для навигации

import tinyHausImages from "../../assets/images/tiny-haus/tinyHausImages";
import wgZimmerImages from "../../assets/images/wg-zimmer/wgZimmerImages";
import apartmentsImages from "../../assets/images/apartments/apartmentsImages";
import postServiceImages from "../../assets/images/post-service/postServiceImages";

const categoriesCards = [
  {
    name: "WG-Zimmer",
    img: wgZimmerImages[7],
    link: "/apartment-info/wg-zimmer", // Добавляем ссылку
  },
  {
    name: "Tiny Haus",
    img: tinyHausImages[1],
    link: "/apartment-info/tiny-haus",
  },
  {
    name: "Apartments",
    img: apartmentsImages[6],
    link: "/apartment-info/apartments",
  },
  {
    name: "Post Service",
    img: postServiceImages[1],
    link: "/apartment-info/post-service",
  },
];

const CategoriesSection = () => {
  return (
    <section className="categories-section">
      <h2>Kategorien</h2>
      <div className="categories-cards">
        {categoriesCards.map((card) => (
          <Link
            // to={card.link}
            key={card.name}
          >
            {/* Оборачиваем в Link */}
            <div className="category-card">
              <img src={card.img} alt={card.name} />
              <h4>{card.name}</h4>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default CategoriesSection;
