const EMAILJS_SERVICE_ID = "service_qq2jpqb";
const EMAILJS_CONTACT_TEMPLATE_ID = "template_9y2jhz8";
const EMAILJS_BOOKING_TEMPLATE_ID = "template_ts8bjvh";
const EMAILJS_USER_ID = "QvrDZHRyDJJss8OWu";

export {
  EMAILJS_SERVICE_ID,
  EMAILJS_CONTACT_TEMPLATE_ID,
  EMAILJS_BOOKING_TEMPLATE_ID,
  EMAILJS_USER_ID,
};
