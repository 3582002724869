const images = [
  require("./image-1.jpg"),
  require("./image-2.jpg"),
  require("./image-3.jpg"),
  require("./image-4.jpg"),
  require("./image-5.jpg"),
  require("./image-6.jpg"),
];

export default images;
