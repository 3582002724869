import React, { useState } from "react";
import { FiPhone, FiMail } from "react-icons/fi";
import ImpressumContent from "./ImpressumContent";
import DatenschutzContent from "./DatenschutzContent";
import "../styles/Footer.styles.scss";

const Footer = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  return (
    <footer className="footer">
      <div className="footer__info">
        <h2>Koby Housing</h2>
        <address>
          Altenhagener Straße 285 <br />
          33729 Bielefeld
        </address>
        <div className="footer__contacts">
          <p>
            <FiPhone className="footer__icon" />
            <a href="tel:+4915753048561">01575 3048561</a>
          </p>
          <p>
            <FiMail className="footer__icon" />
            <a href="mailto:kobylko.elisabeth@gmail.com">
              kobyhousing@gmail.com
            </a>
          </p>
        </div>
      </div>
      <div className="footer__legal">
        <p>© 2024 Koby Housing – Alle Rechte vorbehalten.</p>
        <button
          className="footer__link"
          onClick={() => openModal(<ImpressumContent />)}
        >
          Impressum
        </button>{" "}
        |{" "}
        <button
          className="footer__link"
          onClick={() => openModal(<DatenschutzContent />)}
        >
          Datenschutz
        </button>
      </div>

      {isModalOpen && (
        <div className="modal__overlay" onClick={closeModal}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <div className="modal__header">
              <h2>
                {modalContent.type === ImpressumContent
                  ? "Impressum"
                  : "Datenschutz"}
              </h2>
              <button className="modal__close" onClick={closeModal}>
                &times;
              </button>
            </div>
            <div className="modal__content">{modalContent}</div>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
