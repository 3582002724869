import React from "react";
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import tinyHausImages from "../../assets/images/tiny-haus/tinyHausImages";
import wgZimmerImages from "../../assets/images/wg-zimmer/wgZimmerImages";
import apartmentsImages from "../../assets/images/apartments/apartmentsImages";
import postServiceImages from "../../assets/images/post-service/postServiceImages";
import aappartraumImages from "../../assets/images/aappartraum/aappartraumImages";
import apartmentsEngerImages from "../../assets/images/apartments-enger/apartmentsEngerImages";

const images = [
  tinyHausImages[1],
  tinyHausImages[3],
  tinyHausImages[5],
  wgZimmerImages[2],
  wgZimmerImages[4],
  wgZimmerImages[1],
  apartmentsImages[0],
  apartmentsImages[2],
  apartmentsImages[4],
  aappartraumImages[1],
  aappartraumImages[3],
  apartmentsEngerImages[0],
  apartmentsEngerImages[2],
  postServiceImages[0],
  apartmentsImages[8],
];

const PrevArrow = ({ className, style, onClick }) => {
  return (
    <FaAngleLeft
      className={className + " arrow-icon"}
      style={{ ...style, display: "block", color: "#333", fontSize: "24px" }}
      onClick={onClick}
    />
  );
};

const NextArrow = ({ className, style, onClick }) => {
  return (
    <FaAngleRight
      className={className + " arrow-icon"}
      style={{ ...style, display: "block", color: "#333", fontSize: "24px" }}
      onClick={onClick}
    />
  );
};

const GallerySection = () => {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <section className="gallery-section">
      <h2>Einblicke</h2>
      <div className="gallery-grid">
        <div className="gallery-column">
          <img src={images[0]} alt="horizontal" className="horizontal" />
          <img src={images[1]} alt="square" className="square" />
          <img src={images[2]} alt="vertical" className="vertical" />
          <img src={images[3]} alt="square" className="square" />
          <img src={images[4]} alt="horizontal" className="horizontal" />
        </div>
        <div className="gallery-column">
          <img src={images[5]} alt="vertical" className="vertical" />
          <img src={images[6]} alt="square" className="square" />
          <img src={images[7]} alt="horizontal" className="horizontal" />
          <img src={images[8]} alt="horizontal" className="horizontal" />
          <img src={images[9]} alt="square" className="square" />
        </div>
        <div className="gallery-column">
          <img src={images[10]} alt="horizontal" className="horizontal" />
          <img src={images[11]} alt="square" className="square" />
          <img src={images[12]} alt="vertical" className="vertical" />
          <img src={images[13]} alt="square" className="square" />
          <img src={images[14]} alt="horizontal" className="horizontal" />
        </div>
      </div>
      <Slider {...sliderSettings} className="gallery-slider">
        {images.map((img, index) => (
          <div key={index}>
            <img src={img} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default GallerySection;
