import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BookingContext } from "../../navigator/BookingContext";

const apartments = [
  { value: "tiny-haus", label: "Tiny Haus in Herford" },
  { value: "wg-zimmer", label: "WG-Zimmer Bielefeld Phoenix Haus" },
  { value: "apartments", label: "Apartments Herford" },
  { value: "aappartraum", label: "Aappartraum Herford" },
  { value: "apartments-enger", label: "2 Zimmer Apartments Enger" },
];

const customStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    outline: state.isFocused ? "2px solid #2e2e2e" : "none",
    borderColor: "transparent",
    boxShadow: "none",
  }),
};

const BookSection = () => {
  const { formData, setFormData } = useContext(BookingContext);
  const navigate = useNavigate();

  // Устанавливаем сегодняшнюю дату для проверки и ограничения
  const today = new Date();
  const todayISOString = today.toISOString().split("T")[0]; // Форматируем как YYYY-MM-DD

  useEffect(() => {
    if (!formData.checkIn) {
      setFormData({ ...formData, checkIn: todayISOString });
    }
  }, [formData.checkIn, setFormData]);

  const handleApartmentChange = (selectedOption) => {
    setFormData({ ...formData, apartment: selectedOption });
  };

  const handleDateChange = (date, field) => {
    const selectedDate = date.toISOString().split("T")[0];

    if (field === "checkIn") {
      if (selectedDate < todayISOString) {
        setFormData({ ...formData, checkIn: todayISOString });
      } else {
        setFormData({ ...formData, checkIn: selectedDate });
      }
    } else if (field === "checkOut") {
      const checkInDate = formData.checkIn
        ? new Date(formData.checkIn)
        : new Date();
      const tomorrow = new Date(checkInDate);
      tomorrow.setDate(checkInDate.getDate() + 1);
      const minCheckOutDate = tomorrow.toISOString().split("T")[0];

      if (selectedDate < minCheckOutDate) {
        setFormData({ ...formData, checkOut: minCheckOutDate });
      } else {
        setFormData({ ...formData, checkOut: selectedDate });
      }
    }
  };

  const handleWeiterClick = () => {
    // Обновляем subject перед переходом на экран бронирования
    if (formData.apartment) {
      const updatedSubject = `Buchung - ${formData.apartment.label}`;
      setFormData({ ...formData, subject: updatedSubject });
    }

    console.log("Данные переданы:", formData);
    navigate("/buchen");
  };

  return (
    <section className="book-section" id="book-section">
      <h2>Jetzt buchen</h2>
      <div className="book-controls">
        <div className="control">
          <Select
            className="custom-select"
            styles={customStyles}
            placeholder="Apartment wählen"
            options={apartments}
            value={formData.apartment}
            onChange={handleApartmentChange}
          />
        </div>
        <div className="control">
          <DatePicker
            selected={formData.checkIn ? new Date(formData.checkIn) : null}
            onChange={(date) => handleDateChange(date, "checkIn")}
            placeholderText="Check-In wählen"
            dateFormat="dd.MM.yyyy"
            minDate={today}
            showPopperArrow={false} // Добавим чтобы не было стрелочки на календаре
          />
        </div>
        <div className="control">
          <DatePicker
            selected={formData.checkOut ? new Date(formData.checkOut) : null}
            onChange={(date) => handleDateChange(date, "checkOut")}
            placeholderText="Check-Out wählen"
            dateFormat="dd.MM.yyyy"
            minDate={formData.checkIn ? new Date(formData.checkIn) : today}
          />
        </div>
        <button onClick={handleWeiterClick}>Weiter</button>
      </div>
    </section>
  );
};

export default BookSection;
