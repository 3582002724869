import React, { useContext, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import emailjs from "@emailjs/browser";

import {
  EMAILJS_SERVICE_ID,
  EMAILJS_BOOKING_TEMPLATE_ID,
  EMAILJS_USER_ID,
} from "../emailjsConfig.js";
import { BookingContext } from "../navigator/BookingContext.js";

// Массив с доступными апартаментами
const apartments = [
  { value: "tiny-haus", label: "Tiny Haus in Herford" },
  { value: "wg-zimmer", label: "WG-Zimmer Bielefeld Phoenix Haus" },
  { value: "apartments", label: "Apartments Herford" },
  { value: "aappartraum", label: "Aappartraum Herford" },
  { value: "apartments-enger", label: "2 Zimmer Apartments Enger" },
];

const BookingScreen = () => {
  const { formData, setFormData } = useContext(BookingContext); // Используем данные из контекста

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      checkIn: today,
    }));
  }, [setFormData]); // Убедитесь, что setFormData передается как зависимость

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (name === "apartment") {
      const selectedApartment = apartments.find(
        (apartment) => apartment.value === value
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        apartment: selectedApartment || {},
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === "checkbox" ? e.target.checked : value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        EMAILJS_SERVICE_ID,
        EMAILJS_BOOKING_TEMPLATE_ID,
        {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          subject: formData.subject,
          apartment: formData.apartment.label, // используем label для отправки
          checkIn: formData.checkIn,
          checkOut: formData.checkOut,
          message: formData.message,
        },
        EMAILJS_USER_ID
      )
      .then(
        (result) => {
          alert("Buchung wurde erfolgreich gesendet!");
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "Buchungsanfrage für Apartment",
            apartment: {},
            checkIn: "",
            checkOut: "",
            message: "",
          });
        },
        (error) => {
          alert("Es gab einen Fehler beim Senden der Buchung.");
        }
      );
  };

  const handleCheckOutChange = (e) => {
    const newCheckOutDate = e.target.value;
    const checkInDate = new Date(formData.checkIn);
    const checkOutDate = new Date(newCheckOutDate);

    if (checkOutDate <= checkInDate) {
      checkOutDate.setDate(checkInDate.getDate() + 1);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      checkOut: checkOutDate.toISOString().split("T")[0],
    }));
  };

  return (
    <div className="booking-screen">
      <Header />
      <section className="booking-form-section">
        <h2 className="title">Buchungsanfrage</h2>
        <p className="subtitle">
          Alle mit (<span>*</span>) markierten Felder werden benötigt.
        </p>
        <form className="booking-form" onSubmit={handleSubmit}>
          <label>
            Name <span>*</span>
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />

          <label>
            E-Mail <span>*</span>
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <label>Telefon</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />

          <label>Betreff</label>
          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
          />

          <label>
            Apartment <span>*</span>
          </label>
          <select
            name="apartment"
            value={formData.apartment?.value || ""}
            onChange={handleChange}
            required
          >
            <option value="">Bitte wählen</option>
            {apartments.map((apartment) => (
              <option key={apartment.value} value={apartment.value}>
                {apartment.label}
              </option>
            ))}
          </select>

          <label>
            Check-In Datum <span>*</span>
          </label>
          <input
            type="date"
            name="checkIn"
            value={formData.checkIn}
            onChange={handleChange}
            required
            min={formData.checkIn}
          />

          <label>
            Check-Out Datum <span>*</span>
          </label>
          <input
            type="date"
            name="checkOut"
            value={formData.checkOut}
            onChange={handleCheckOutChange}
            required
            min={formData.checkIn}
          />

          <label>Nachricht</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
          />

          <button type="submit">Buchung senden</button>
        </form>
      </section>
      <Footer />
    </div>
  );
};

export default BookingScreen;
