// CookieBanner.js
import React, { useState, useEffect } from "react";

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Проверяем, была ли плашка уже закрыта ранее
    const isCookieBannerClosed = localStorage.getItem("cookieBannerClosed");
    if (!isCookieBannerClosed) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem("cookieBannerClosed", "true");
  };

  return (
    isVisible && (
      <div className="cookie-banner">
        <div className="banner-text">
          <h4>Hinweis:</h4>
          <p>
            Diese Website verwendet Cookies, um Ihnen den bestmöglichen Service
            zu gewährleisten. Durch die Nutzung unserer Internetseite stimmen
            Sie der Nutzung von Cookies gemäß unserer Cookie-Richtlinie zu.
          </p>
        </div>

        <div className="cookie-banner__buttons">
          <button onClick={handleClose} className="cookie-banner__close-btn">
            Schließen
          </button>
        </div>
      </div>
    )
  );
};

export default CookieBanner;
