import React, { useState, useEffect } from "react";
import { FiMenu, FiX, FiPhone, FiMail } from "react-icons/fi";
import { Link } from "react-router-dom";
import "../styles/Header.styles.scss";

const Header = ({ home = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(!home); // По умолчанию "scrolled"

  const toggleMenu = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    if (home) {
      const handleScroll = () => setIsScrolled(window.scrollY > 50);
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [home]);

  const handleLogoClick = () => {
    // Прокручиваем страницу к верху плавно
    window.scrollTo({ top: 0, behavior: "smooth" });
    // Закрываем меню при клике на логотип
    setIsOpen(false);
  };

  const headerClass = `header ${isScrolled ? "header--scrolled" : ""} ${
    isOpen ? "header--icon-white" : ""
  }`;

  const menuOverlayClass = `menu-overlay ${isOpen ? "menu-overlay--open" : ""}`;

  return (
    <header className={headerClass}>
      <Link to="/" className="header__logo" onClick={handleLogoClick}>
        <h1>Koby housing</h1>
      </Link>

      <button className="burger-icon" onClick={toggleMenu}>
        {isOpen ? <FiX size={30} /> : <FiMenu size={30} />}
      </button>

      <div className={menuOverlayClass}>
        <nav className="menu">
          <ul>
            <div>
              <p className="category-name">Herford</p>
              <li>
                <Link to="/apartment-info/tiny-haus" onClick={toggleMenu}>
                  Tiny Haus
                </Link>
              </li>
              <li>
                <Link to="/apartment-info/apartments" onClick={toggleMenu}>
                  Apartments
                </Link>
              </li>
              <li>
                <Link to="/apartment-info/aappartraum" onClick={toggleMenu}>
                  Aappartraum
                </Link>
              </li>
            </div>
            <div>
              <p className="category-name">Enger</p>
              <li>
                <Link
                  to="/apartment-info/apartments-enger"
                  onClick={toggleMenu}
                >
                  2 Zimmer Apartments
                </Link>
              </li>
            </div>
            <div>
              <p className="category-name">Bielefeld</p>
              <li>
                <Link to="/apartment-info/wg-zimmer" onClick={toggleMenu}>
                  WG-Zimmer
                </Link>
              </li>
            </div>
          </ul>
        </nav>

        <div className="kontakt-category">
          <Link to="/kontakt" onClick={toggleMenu}>
            Kontakt
          </Link>
        </div>

        {/* Контактная информация внизу экрана */}
        <div className="contact-info">
          <p>
            <FiPhone className="contact-icon" />{" "}
            <a href="tel:+4915753048561">01575 3048561</a>
          </p>
          <p>
            <FiMail className="contact-icon" />{" "}
            <a href="mailto:kobyhousing@gmail.com">kobyhousing@gmail.com</a>
          </p>
        </div>
      </div>
    </header>
  );
};

export default Header;
