import React from "react";

const facilitiesCards = [
  {
    name: "Waschmaschine",
    icon: "https://cdn-icons-png.flaticon.com/512/2333/2333366.png",
  },
  {
    name: "Flatscreen-TV",
    icon: "https://cdn-icons-png.flaticon.com/512/3474/3474360.png",
  },
  {
    name: "Arbeitstisch",
    icon: "https://cdn-icons-png.flaticon.com/512/4861/4861112.png",
  },
  {
    name: "Fön",
    icon: "https://cdn-icons-png.flaticon.com/512/6367/6367568.png",
  },
  {
    name: "Küche mit E-Geräten",
    icon: "https://cdn-icons-png.flaticon.com/512/2237/2237462.png",
  },
  {
    name: "Kostenloses W-LAN",
    icon: "https://cdn-icons-png.flaticon.com/512/4305/4305650.png",
  },
  {
    name: "Teilweise Balkon",
    icon: "https://cdn-icons-png.flaticon.com/512/72/72343.png",
  },
  {
    name: "Badewanne",
    icon: "https://cdn-icons-png.flaticon.com/512/1190/1190034.png",
  },
  {
    name: "Kühlschrank",
    icon: "https://cdn-icons-png.flaticon.com/512/3474/3474313.png",
  },
  {
    name: "Kaffee & Snacks (Aufpreis)",
    icon: "https://cdn-icons-png.flaticon.com/512/2553/2553651.png",
  },
  {
    name: "Haustiere erlaubt",
    icon: "https://cdn-icons-png.flaticon.com/512/887/887344.png",
  },
  {
    name: "Garten",
    icon: "https://cdn-icons-png.flaticon.com/512/427/427584.png",
  },
  {
    name: "Grillmöglichkeit",
    icon: "https://cdn-icons-png.flaticon.com/512/878/878623.png",
  },
  {
    name: "Parkplatz",
    icon: "https://cdn-icons-png.flaticon.com/512/708/708949.png",
  },
  {
    name: "Kaffeemaschine",
    icon: "https://cdn-icons-png.flaticon.com/512/809/809550.png",
  },
];

const FacilitiesSection = () => {
  return (
    <section className="facilities-section">
      <h2>Ausstattung</h2>
      <div className="facilities-cards">
        {facilitiesCards.map((facility) => {
          return (
            <div>
              <img src={facility.icon} alt="img" />
              <h4>{facility.name}</h4>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default FacilitiesSection;
