import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import tinyHausImages from "../../assets/images/tiny-haus/tinyHausImages";
import wgZimmerImages from "../../assets/images/wg-zimmer/wgZimmerImages";
import apartmentsImages from "../../assets/images/apartments/apartmentsImages";
import postServiceImages from "../../assets/images/post-service/postServiceImages";

const heroItems = [
  {
    name: "Tiny Haus",
    image: tinyHausImages[1],
    link: "/apartment-info/tiny-haus",
  },
  {
    name: "Apartments Herford",
    image: apartmentsImages[6],
    link: "/apartment-info/apartments",
  },
  {
    name: "Post Service",
    image: postServiceImages[1],
    link: "/apartment-info/post-service",
  },
  {
    name: "WG-Zimmer",
    image: wgZimmerImages[7],
    link: "/apartment-info/wg-zimmer",
  },
];

const HeroSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % heroItems.length);
        setFade(true);
      }, 300);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const { name, image, link } = heroItems[currentIndex];

  return (
    <section className="hero-section">
      <div className={`background ${fade ? "fade-in" : "fade-out"}`}>
        <img src={image} alt={name} />
      </div>
      <div className="content">
        <h3 className={`heading ${fade ? "slide-in" : "slide-out"}`}>{name}</h3>
        <Link to={link}>
          <button>Mehr Erfahren</button>
        </Link>
      </div>
    </section>
  );
};

export default HeroSection;
